import React, {useState} from "react";

import "./text-box.scss";
import handleViewport from "react-in-viewport";

const TextBoxRaw = ({id, text, secondary, inViewport, forwardedRef}) => {
  const [show, setShow] = useState(false);
  if (inViewport && !show) {
    setShow(true);
  }

  return (
    <div id={id} className={`my-text-box ${show ? "my-text-box--show" : ""}`} ref={forwardedRef}>
      <div className="my-text-box__border" />
      <div className="my-text-box__content">
        <div>{text}</div>
        <div className="my-text-box__secondary">{secondary}</div>
      </div>
    </div>
  );
};

export const TextBox = handleViewport(TextBoxRaw, {rootMargin: "-100px"}, { disconnectOnLeave: true });
