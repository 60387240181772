import React, {useState} from "react";

import "./video.scss";
import {ParallaxBanner} from "react-scroll-parallax";

export const Video = ({id, video, preview, previewWP, previewSmall, previewSmallWP, controlsEnabled = false, autoplay = true}) => {
  const [isPreview, setIsPreview] = useState(true);

  return (
    <div
      id={id}
      className="my-video"
      onClick={() => setIsPreview(false)}>
      {isPreview &&
      <ParallaxBanner
        className="my-video__preview my-video--parallax"
        layers={[
          {
            image: preview,
            amount: 0.3,
          },
        ]}
        style={{
          height: '100vh',
        }}
      >
      </ParallaxBanner>}
      {isPreview &&
      <picture onClick={() => setIsPreview(false)}>
        <source media="(min-width:480px)" srcSet={previewWP} type="image/webp" />
        <source media="(min-width:480px)" srcSet={preview} />
        <source srcSet={previewSmallWP} type="image/webp" />
        <img loading="lazy" className="my-video__preview my-video--static" src={previewSmall} alt="Video preview" />
      </picture>}

      {!isPreview &&
      <video className="my-video__player" controls={controlsEnabled} autoPlay={autoplay} muted={autoplay}>
        <source type="video/mp4" src={video}/>
      </video>
      }
    </div>
  );
}
