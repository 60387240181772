import * as React from "react"
import SEO from "../components/SEO/SEO";
import { Layout } from "../components/layout/layout";
import { Quote } from "../components/quote/quote";
import { TextBox } from "../components/text-box/text-box";
import { Video } from "../components/video/video";

import previewSmall from "../images/preview-video-small.png";
import preview from "../images/preview-video.png";
import previewSmallWP from "../images/preview-video-small.webp";
import previewWP from "../images/preview-video.webp";
import promoVideo from "../images/promo.mp4";
import { Arrow } from "../components/arrow/arrow";
import FormContainer from "../components/form-container/form-container";

const ChartersPage = () => {
  return (
    <main>
      <SEO
        title="Charters"
        pathname="/charters"
        description="Whether you are interested in a luxury motor yacht, small boat or a classic sailing yacht, we are able to find the perfect solution to guarantee you an unforgettable yachting experience around Miami and many more places."
      />
      <Layout page="charters" bannerBottomAligned={false}>
        <Quote
          text="We are passionate about matching every client with their perfect boat and dream itinerary and"
          highlightedText="creating some of the best memories of their lives"
          author="Sabrina Gonzalez"
        />
        <Arrow alignBottom={false} url="charters-1" />
        <TextBox
          id="charters-1"
          text="Whether you are interested in a luxury motor yacht, small boat or a classic sailing yacht, we are able to find the perfect solution to guarantee you an unforgettable yachting experience around Miami and many more places. We are passionate about matching every client with their perfect boat and dream itinerary and creating some of the best memories of their lives. Contact us today and lets go yachting."
        />
        <Arrow url="charters-2" />
        <Video
          id="charters-2"
          url="https://www.youtube.com/embed/pwjsx6T9xdM"
          preview={preview}
          previewWP={previewWP}
          previewSmall={previewSmall}
          previewSmallWP={previewSmallWP}
          video={promoVideo}
          controlsEnabled={true}
        />
        <Arrow url="charters-3" />
        <Quote
          id="charters-3"
          text="Our passion is yachting and our mission is to help others"
          highlightedText="enjoy the yachting lifestyle."
        />
        <FormContainer topic="Charter" pagePath="charters" />
      </Layout>
    </main>
  )
}

export default ChartersPage;
